import React from 'react';
import Contact from './components-home/Contact';
import Feature from './components-home/Feature';
import FeatureOne from './components-home/FeatureOne';
import FeatureTwo from './components-home/FeatureTwo';
import Heroarea from './components-home/Heroarea';
import Services from './components-home/Services';
import Preface from './components-home/Preface';
import Howitworks from './components-home/Howitworks';
import Screenshots from './components-home/Screenshots';



const Home = () => {
  
    return (
      <div>
      <Heroarea />
      <FeatureOne />
      <Feature />  
      <FeatureTwo />
      <Services />
      <Screenshots />
      <Howitworks />
      <Preface />
      <Contact />
      </div>


    );
 
}



export default Home;
