import React, { Component } from "react";
import HeaderBottom from "../components-global/Header-bottom";

class PrivacyPolicy extends Component {
  render() {
    let propsData = this.props.data;
    let headerPageTitle = propsData.pagetitle.policytitle;

    return (
      <div>
        <HeaderBottom headertitle={headerPageTitle} />
        <section id="blog-full" className="p-80px-tb gray-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-blog-content page-content">
                  <div className="blog-content">
                    <h1 className="m-20px-b">Gizlilik Politikası</h1>

                    <div className="single-blog-post">
                      <p>
                        İşbu Gizlilik Politikası, QP Face Bilgi Teknolojileri
                        A.Ş. ("Şirket"), QP ("Uygulama") adlı mobil uygulamaya
                        erişen veya bu uygulamayı kullanan kişilerin
                        ("Kullanıcılar") kişisel bilgilerinin toplanması,
                        kullanılması ve işlenmesi ile ilgilidir.
                        <h3> Kişisel Verilerin İşlenmesi</h3>
                        Uygulama, kullanıcıların kişisel verilerini toplamak ve
                        işlemektedir. Bu veriler arasında IP adresleri ve/veya
                        benzersiz cihaz kimlikleri gibi otomatik olarak
                        oluşturulan bilgiler bulunmaktadır. Kullanıcılar,
                        tercihlerine bağlı olarak coğrafi konum bilgilerini
                        paylaşmayı seçebilirler. Ancak, coğrafi konum
                        bilgilerinin paylaşımını mobil cihaz ayarlarından
                        engellemek mümkündür. Ayrıca, Uygulama'nın kullanımı
                        sırasında otomatik olarak oluşturulan bilgilerle ilgili
                        olarak kullanıcı tarafından girilen ad, soyad, e-posta
                        ve telefon numarası gibi kişisel bilgiler de
                        saklanmaktadır. Bu kişisel verilerin işlenme amaçları ve
                        süreçleri hakkında daha fazla bilgi için lütfen "Kişisel
                        Verilerin İşlenmesine İlişkin Aydınlatma Metni"ni
                        inceleyiniz.
                        <h3> Fikri Mülkiyet Hakları</h3>
                        Uygulama'nın tüm fikri mülkiyet hakları QP'ye aittir.
                        Uygulama'nın izinsiz kullanımı, çoğaltılması,
                        kopyalanması veya diğer herhangi bir yasa dışı faaliyet,
                        ilgili yasal düzenlemelere tabi tutulur. Kullanıcılar,
                        bu haklara saygı göstermekle yükümlüdürler. İlgili
                        mevzuata aykırı davranılması durumunda, Şirket yasal
                        yollara başvurma hakkını saklı tutar.
                        <h3> Hizmet ve Gizlilik Koşulları</h3>
                        Şirket, kullanıcıların kişisel bilgi ve veri güvenliğini
                        sağlamak adına gerekli tüm tedbirleri almaktadır.
                        Kullanıcılar da Uygulama'yı kullanarak bu Gizlilik
                        Politikası hükümlerine uymayı kabul ederler.
                        Kullanıcıların verdiği bilgiler, belirli amaçlar dışında
                        kullanılmayacak ve üçüncü şahıslarla paylaşılmayacaktır.
                        <h3>Bilgilerin Doğruluğu ve Güncelliği</h3>
                        Kullanıcılar, sağladıkları bilgilerin doğruluğundan ve
                        güncelliğinden sorumludur. Bilgilerin doğruluğunu
                        sağlamak ve herhangi bir değişiklik olduğunda
                        güncellemek, kullanıcıların sorumluluğundadır. Şirket,
                        güvenlik ve gizlilik prensiplerine uymayan
                        kullanıcıların olası sonuçlarından sorumlu tutulamaz.
                        <h3> Değişiklikler</h3>
                        Şirket, Uygulama üzerinden sunulan hizmetleri, bilgileri
                        ve Gizlilik Politikası'nı önceden bildirimde
                        bulunmaksızın değiştirme, askıya alma veya durdurma
                        hakkını saklı tutar. Gizlilik Politikası üzerinde
                        yapılacak değişiklikler Kullanıcıların
                        sorumluluğundadır. Güncel politikaları takip etmek
                        Kullanıcıların kendi sorumluluğundadır.
                        <h3> Sorumluluklar</h3>
                        Uygulamanın kullanımı sırasında oluşabilecek hatalardan,
                        kesintilerden, gecikmelerden, virüslerden, hatalardan
                        ve/veya sistem arızalarından kaynaklanan doğrudan veya
                        dolaylı zararlardan Şirket sorumlu tutulamaz.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PrivacyPolicy;
