import React, { useState, useEffect } from "react";
import HeaderBottom from "../components-global/Header-bottom";
import "./login.css";
import { logout, deleteAccount } from "../../FireBaseConfig";
import { useHistory } from "react-router";
import { auth } from "../../FireBaseConfig";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

function ProfileSettings() {
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    logout()
      .then(() => {
        auth
          .signOut()
          .then(() => {
            console.log("Logout successful");
            toast.success("Başarıyla çıkış yapıldı");
            history.push("/login");
          })
          .catch((error) => {
            console.error("Firebase Logout error:", error);
          });
      })
      .catch((error) => {
        console.error("Custom Logout error:", error);
      });
  };
  const handleDeleteAccount = () => {
    deleteAccount()
      .then((result) => {
        if (result) {
          console.log("Account deleted successfully");
          toast.success("Hesabınız başarıyla silindi");
          history.push("/Signup");
        } else {
          console.error("Account deletion failed");
        }
      })
      .catch((error) => {
        console.error("Delete account error:", error);
      });
  };

  return (
    <div>
      <HeaderBottom />
      <section className="p-80px-tb gray-bg form">
        <div>
          {user ? (
            <p className="text-welcome">Hoşgeldin, {user.email} 🚀 </p>
          ) : (
            <>
              <p className="text-welcome">
                Profilinizi düzenlemeye devam etmek için lütfen giriş yapın.
              </p>
            </>
          )}
        </div>
        {user ? (
          <div className="input">
            <button
              onClick={handleDeleteAccount}
              className="value button-submit"
            >
              Hesabı Sil
            </button>
            <button onClick={handleLogout} className="value button-submit">
              Çıkış Yap
            </button>
          </div>
        ) : (
          <Link to="/login">
            <button className="button-submit"> Giriş Yap</button>
          </Link>
        )}
      </section>
    </div>
  );
}

export default ProfileSettings;
