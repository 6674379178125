import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink as HLink } from "react-router-hash-link";


class Header extends Component {
  render() {
    let imgattr = "image";


    return (
      <div>
        <header id="home">
          <nav className="navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header">
            <div className="container">
              <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/logo-white.png"}
                  alt={imgattr}
                  width={150}
                  height={50}
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCodeply"
              >
                <i className="icofont-navigation-menu"></i>
              </button>
              <div className="collapse navbar-collapse" id="navbarCodeply">
                <ul className="nav navbar-nav ml-auto">
                  <li>
                    <HLink className="nav-link" smooth to={`/#home`}>
                      Anasayfa
                    </HLink>{" "}
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#feature1`}>
                      Biz kimiz?
                    </HLink>
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#feature`}>
                      Çözümlerimiz
                    </HLink>
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#screenshots`}>
                      QP App
                    </HLink>
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#contact`}>
                      İletişim
                    </HLink>
                  </li>
                  {/* <li><HLink className="nav-link" smooth to={`/#testimonials`}>Testimonial</HLink></li>
                     <li><Link to="/login">Login</Link></li> */}
                  {/* <li>
                        <HLink className="nav-link" smooth to={`/#blog`}>Blog</HLink>
                        <span className="sub-menu-toggle" role="navigation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <i className="icofont-rounded-down"></i>
                        </span>
                        <ul className="sub-menu">
                           <li><Link to="/blog-full">Blog Full</Link></li>
                           <li><Link to="/blog-2-col">Blog 2 col</Link></li>
                           <li><Link to="/blog-3-col">Blog 3 col</Link></li>
                           <li><Link to="/blog-full-left-sidebar">Blog left sidebar</Link></li>
                           <li><Link to="/blog-full-right-sidebar">Blog right sidebar</Link></li>
                           <li><Link to="/single-blog">Single blog</Link></li>
                           <li><Link to="/single-left-sidebar">Single left sidebar</Link></li>
                           <li><Link to="/single-right-sidebar">Single right sidebar</Link></li>
                           
                        </ul>
                     </li> */}
                  <Link to="/login">
                    <button class="Btn">
                      <div class="sign">
                        <svg viewBox="0 0 512 512">
                          <path d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"></path>
                        </svg>
                      </div>

                      <div class="text">Giriş</div>
                    </button>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
