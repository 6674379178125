import { createSlice } from "@reduxjs/toolkit";

// Attempt to parse the user data from localStorage, handling potential errors
let parsedUserData;
try {
    const storedUserData = localStorage.getItem("user");
    parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;
} catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    parsedUserData = null;
}

const initialState = {
    user: parsedUserData,
};

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            localStorage.setItem("user", JSON.stringify(action.payload));
            state.user = action.payload;
        },
        logout: (state) => {
            localStorage.removeItem('user');
            state.user = null; // Update to set the user to null upon logout
        },
    },
});

export const { login, logout } = auth.actions;
export default auth.reducer;
