// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider , signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword , signOut, deleteUser , sendPasswordResetEmail} from "firebase/auth"
import toast from "react-hot-toast";


const firebaseConfig = {
  apiKey: "AIzaSyB2mQkAQaXGRZeCu35ch0BsQD0fciwqdiA",
  authDomain: "qpfacedev.firebaseapp.com",
  projectId: "qpfacedev",
  storageBucket: "qpfacedev.appspot.com",
  messagingSenderId: "694173264979",
  appId: "1:694173264979:web:341153dccd58a6374deaa6",
  measurementId: "G-C4WJK1378Z"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export {auth, provider}

export const register = async(email, password) => {

  try {
    const {user} = await createUserWithEmailAndPassword(auth, email, password)
    return user

  } 
  catch (error){
    toast.error(error.message)
  }

}


export const login =  async (email, password) => {

  try{
    const {user} = await signInWithEmailAndPassword(auth, email, password)
    return user

  } catch (error) {
    toast.error(error.message)

  }
}

export const logout =  async () => {

  try{
    await signOut(auth)
    return true

  } catch (error) {
    toast.error(error.message)

  }
}

export const deleteAccount = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      await deleteUser(user);
      return true;
    } else {
      throw new Error("No user found");
    }
  } catch (error) {
    toast.error(error.message);
  }
};


export const loginWithGoogle = async () => {
  try {
    const { user } = await signInWithPopup(auth, provider);
    console.log('Google Sign-In Successful:', user);
    return user;
  } catch (error) {
    console.error('Google Sign-In Error:', error.message);
    toast.error(error.message);
  }
};


export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent successfully.");
    return true;
  } catch (error) {
    console.error("Error sending password reset email:", error.message);
    throw error;
  }
};